import React from "react";
import { graphql } from "gatsby";
import IndexTemplate from "../templates/home";

const environment = {
  lang: "nl",
  path: "/",
  altHrefLang: [
    {
      lang: "nl",
      path: "/",
    },
    {
      lang: "en",
      path: "/en",
    },
  ],
  theme: "green",
};

const IndexNl = (props) => {
  return <IndexTemplate {...props} environment={environment} />;
};

export default IndexNl;

export const IndexQueryNl = graphql`
  {
    imgDesktop: file(relativePath: { eq: "index-banner-desktop-nl.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imgMobile: file(relativePath: { eq: "index-banner-mobile-nl.png" }) {
      childImageSharp {
        gatsbyImageData(width: 820, layout: CONSTRAINED)
      }
    }
  }
`;
